<div class="container" *transloco="let t">
  <mat-toolbar>
    <app-logo [logoSvgSrc]="'/assets/images/q-logo-light.svg'"> </app-logo>
    <button mat-icon-button (click)="closeDialogClick()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </mat-toolbar>
  <nav>
    <a
      *ngFor="let menuItem of menuItems$ | async"
      class="menu-item"
      (click)="navItemClick()"
      [routerLink]="menuItem.path"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <ng-container *ngIf="menuItem.localisationKey; else nonTranslatedText">
        {{ t(menuItem.localisationKey) }}
      </ng-container>
      <ng-template #nonTranslatedText>{{ menuItem.linkText }}</ng-template>
    </a>

    <mat-divider></mat-divider>

    <a
      *ngFor="let profileMenuItem of profileItems$ | async"
      class="menu-item"
      (click)="profileMenuItemClick(profileMenuItem.type)"
      [routerLink]="profileMenuItem.path"
      [routerLinkActive]="'active'"
    >
      <ng-container *ngIf="profileMenuItem.localisationKey; else nonTranslatedText">
        {{ t(profileMenuItem.localisationKey) }}
      </ng-container>
      <ng-template #nonTranslatedText>{{ profileMenuItem.linkText }}</ng-template>
    </a>
  </nav>
</div>
