<app-breadcrumb></app-breadcrumb>

<div class="content-container">
  <section class="column">
    <ng-content select="[leftColumn]"></ng-content>
  </section>

  <section class="column">
    <ng-content select="[rightColumn]"></ng-content>
  </section>
</div>
