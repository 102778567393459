import { Component, EventEmitter, Output } from '@angular/core';

import { AlertConfig } from '../../interfaces/alert-config';

@Component({
  selector: 'app-alert-container',
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.scss'],
})
export class AlertContainerComponent {
  protected alertConfigs: AlertConfig[] = [];

  @Output() alertCountChange: EventEmitter<number> = new EventEmitter();

  addAlert(config: AlertConfig): void {
    const startLength = this.alertConfigs.length;
    /**
     * If already showing max number of alerts (or more, possible because config is changeable on the fly)
     * then first remove elements so that number of alerts remaining is max - 1.
     */
    if (config.limitAlertNumber && this.alertConfigs.length >= config.limitAlertNumber) {
      const numElementsToRemove = this.alertConfigs.length - config.limitAlertNumber + 1;
      this.alertConfigs.splice(0, numElementsToRemove);
    }

    this.alertConfigs.push(config);

    if (startLength !== this.alertConfigs.length) {
      this.alertCountChange.next(this.alertConfigs.length);
    }
  }

  removeAlert(config: AlertConfig): void {
    const position = this.alertConfigs.indexOf(config);
    this.alertConfigs.splice(position, 1);
    this.alertCountChange.next(this.alertConfigs.length);
  }
}
