import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NavItem } from '../../interfaces/nav-item.model';
import { headerNavLinks, profileMenuNavLinks } from '../../constants/header-nav-link';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  navItems$: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>(headerNavLinks);
  profileMenuNavItems$: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>(profileMenuNavLinks);
}
