/**
 * Q Header
 * Page top container for branding and navigation
 * @author Cedomir Djosic <cedomir.djosic@q.agency>
 */
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Injector } from '@angular/core';
import { SessionRepository } from 'src/app/auth/state/session.repository';
import { SessionService } from 'src/app/auth/state/session.service';

import { LinkType } from '../../interfaces/nav-item.model';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { HeaderService } from './header.service';
import { MOBILE_MENU_OVERLAY_REF } from './tokens/mobile-menu-overlay-ref.token';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  loggedIn$ = this.sessionRepository.loggedIn$;
  constructor(
    public headerService: HeaderService,
    private overlay: Overlay,
    private sessionRepository: SessionRepository,
    private sessionService: SessionService
  ) {}

  toggleMenu(): void {
    const overlayRef = this.overlay.create({
      'width': '100%',
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
    const injector = Injector.create({ providers: [{ provide: MOBILE_MENU_OVERLAY_REF, useValue: overlayRef }] });
    const userProfilePortal = new ComponentPortal(MobileMenuComponent, undefined, injector);
    overlayRef.attach(userProfilePortal);
    overlayRef.backdropClick().subscribe(_ => overlayRef.dispose());
  }

  profileMenuItemClick(type?: LinkType): void {
    if (type === LinkType.Logout) {
      this.sessionService.logout();
    }
  }
}
