<div class="nav-toolbar">
  <nav class="nav-container">
    <app-logo [logoSvgSrc]="'./assets/images/q-logo.svg'"> </app-logo>

    <ul class="nav-items">
      <li *ngFor="let item of footerService.navItems$ | async" app-nav-item [navItem]="item" class="nav-item"></li>
    </ul>
    <div class="desktop-nav-actions">
      <a mat-icon-button target="_blank" href="https://google.com">
        <mat-icon svgIcon="instagram-logo"></mat-icon>
      </a>
      <a mat-icon-button target="_blank" href="https://facebook.com">
        <mat-icon svgIcon="facebook-logo"></mat-icon>
      </a>
      <a mat-icon-button target="_blank" href="https://twitter.com">
        <mat-icon svgIcon="twitter-logo"></mat-icon>
      </a>
      <a mat-icon-button target="_blank" href="https://youtube.com">
        <mat-icon svgIcon="youtube-logo"></mat-icon>
      </a>
    </div>
  </nav>
</div>
