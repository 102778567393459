import { NavItem } from '../interfaces/nav-item.model';

export const footerNavLinks: NavItem[] = [
  {
    linkText: 'Home',
    path: '/home',
  },
  {
    linkText: 'Teams',
    path: '/teams',
  },
  {
    linkText: 'Privacy',
    path: '/privacy',
  },
  {
    linkText: 'Security',
    path: '/security',
  },
];
