import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntil } from 'rxjs';

import { OneShotAlertTimer } from './one-shot-alert-timer';
import { AlertConfig } from './interfaces/alert-config';
import { AlertTimer } from './interfaces/alert-timer';
import { alertAnimations } from './constants/alert-animations';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [alertAnimations.alertState],
})
export class AlertComponent implements OnInit, OnDestroy {
  /** Timer used to automatically dismiss alert after set duration. */
  private dismissTimer: AlertTimer | null = null;

  private _config: AlertConfig = {};

  selectedPortal: TemplatePortal | ComponentPortal<unknown> | null = null;
  animationState = 'void';

  @HostBinding('class') class = 'alert-container';
  @ViewChild('contentStringTemplate', { static: true }) contentStringTemplate!: TemplateRef<unknown>;

  @Output() dismissClick: EventEmitter<void> = new EventEmitter();

  /** Emiter notifying that this alert timer has expired and alert should be closed */
  @Output() timerExpire: EventEmitter<void> = new EventEmitter();

  /** Emiter for notifying that alert has entered the view. */
  @Output() enter: EventEmitter<void> = new EventEmitter();

  /** Emitter for notifying that alert has left the view. */
  @Output() exit: EventEmitter<void> = new EventEmitter();

  @Input() set config(config: AlertConfig) {
    this._config = config;

    /**
     * Applying the alertType as container class as that is enough for our current needs.
     * If additional classes are needed in the future, {@link AlertConfig} can be easily
     * extended to incorporate containerClass field.
     */
    this.applyAlertClass(config.alertType ?? '');

    if (config.duration && config.duration > 0) {
      this.setupDismissTimer(config.duration);
    }
  }

  get config(): AlertConfig {
    return this._config;
  }

  @HostBinding('@state') get state(): string {
    return this.animationState;
  }

  constructor(private elementRef: ElementRef<HTMLElement>, private viewContainerRef: ViewContainerRef) {}

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.dismissTimer && !this.config.disableKeepLiveOnMouseOver) {
      this.dismissTimer.pause();
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.dismissTimer && !this.config.disableKeepLiveOnMouseOver) {
      this.dismissTimer.resume();
    }
  }

  ngOnInit(): void {
    if (this.config.content instanceof TemplateRef) {
      this.selectedPortal = new TemplatePortal(this.config.content, this.viewContainerRef);
    } else if (this.config.content instanceof Type) {
      this.selectedPortal = new ComponentPortal(this.config.content, this.viewContainerRef);
    } else {
      this.selectedPortal = new TemplatePortal(this.contentStringTemplate, this.viewContainerRef);
    }
    this.animationState = 'visible';
    this.enter.next();
    this.enter.complete();
  }

  ngOnDestroy(): void {
    this.exit.next();
    this.exit.complete();
  }

  setupDismissTimer(duration: number): void {
    this.dismissTimer = new OneShotAlertTimer();
    this.dismissTimer.expired$.pipe(takeUntil(this.exit)).subscribe(() => {
      this.timerExpire.next();
    });
    this.dismissTimer.start(duration);
  }

  dismiss(): void {
    this.dismissClick.next();
  }

  private applyAlertClass(containerClass: string): void {
    const element: HTMLElement = this.elementRef.nativeElement;
    element.classList.add(containerClass);
  }
}
