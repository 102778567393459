import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-auth-content',
  templateUrl: './auth-content.component.html',
  styleUrls: ['./auth-content.component.scss'],
})
export class AuthContentComponent {
  @Input() title: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() agreementText: string | undefined;
  @Input() termsOfServiceLinkText: string | undefined;
}
