import { Link } from './link.model';

/**
 * @interface NavItem
 * @extends Link
 *
 * e.g.
 * \<a class="{{navItemAnchorCSSClass}}"\>{{linkText}}\<\/a\>
 *
 */
export interface NavItem extends Link {
  linkText: string;
  localisationKey?: string;

  // Disable the no-explicit-any rule since we are using the type definition for ngClass that uses any.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navItemAnchorCSSClass?: string | string[] | Set<string> | { [klass: string]: any };

  /**
   * Other than path, the link can also have an action
   * that requires additional functionality other than just navigating
   * from the page, e. g. Log out link should also clear session store
   * etc. Because of this we need some way of tracking type of link so
   * that we can know and react to click acordingly.
   */
  type?: LinkType;
}

/**
 * Append additional actions to this type if required.
 */
export enum LinkType {
  Logout,
}
