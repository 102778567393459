import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PortalModule } from '@angular/cdk/portal';

import { AlertComponent } from './alert.component';
import { AlertContainerComponent } from './components/alert-container/alert-container.component';

@NgModule({
  declarations: [AlertComponent, AlertContainerComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, PortalModule],
})
export class AlertModule {}
