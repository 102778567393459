/**
 * Logo Component made to be container for SVG logo
 * @author Cedomir Djosic <cedomir.djosic@q.agency>
 *
 * @input {string} logoSvgSrc - represents path to SVG file
 * @input {string} logoLink - for attr.href param (default value '/')
 * @input {string} logoSvgCSSClass - you can style editable SVG even in global style.scss file
 */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() logoSvgSrc: string;
  @Input() logoLink: string | null;

  /** @param {string} logoSvgCSSClass CSS class will help us to change SVG if editable */
  @Input() logoSvgCSSClass: string | null;

  constructor() {
    this.logoSvgSrc = '';
    this.logoLink = '/';
    this.logoSvgCSSClass = null;
  }
}
