import { AnimationTriggerMetadata, animate, state, style, transition, trigger } from '@angular/animations';

export const alertAnimations: {
  readonly alertState: AnimationTriggerMetadata;
} = {
  /** Animation that shows and hides a snack bar. */
  alertState: trigger('state', [
    state(
      'void, hidden',
      style({
        transform: 'scale(0.8)',
        opacity: 0,
      })
    ),
    state(
      'visible',
      style({
        transform: 'scale(1)',
        opacity: 1,
      })
    ),
    transition('* => visible', animate('150ms cubic-bezier(0, 0, 0.2, 1)')),
  ]),
};
