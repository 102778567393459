<ng-container *transloco="let t; read: 'notFound'">
  <h1>{{ t('title') }}</h1>
  <p class="paragraph-intro">
    {{ t('notFound') }}
  </p>
  <p>
    {{ t('notExistPage') }}
  </p>
  <button mat-raised-button routerLink="/" color="primary" class="mt-standard">
    {{ t('goToHome') }}
  </button>
</ng-container>
