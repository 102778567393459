import { InjectionToken } from '@angular/core';

import { AlertConfig } from '../interfaces/alert-config';

export const defaultAlertConfig: AlertConfig = {
  duration: 3000,
  disableKeepLiveOnMouseOver: false,
  alertType: 'info',
};

/**
 * Injection token to be used to provide default values for alert component.
 *
 * Example of overriding default config, just provide alternative config like so:
 *
 * providers: [{ provide: ALERT_DEFAULT_CONFIG, useValue: { duration: 1000 } }],
 */
export const ALERT_DEFAULT_CONFIG = new InjectionToken<AlertConfig>('alert-default-options', {
  providedIn: 'root',
  factory: (): AlertConfig => {
    return defaultAlertConfig;
  },
});
