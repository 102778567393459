import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbConfig } from './interfaces/breadcrumb-config.model';
import { BREADCRUMB_OPTIONS } from './tokens/breadcrumb-options.token';

export interface BreadcrumbModuleOptions {
  breadcrumbOptionsProvider?: Provider;
  config?: BreadcrumbConfig;
}

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, RouterModule, MatIconModule],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {
  static forRoot(options: BreadcrumbModuleOptions): ModuleWithProviders<BreadcrumbModule> {
    return {
      ngModule: BreadcrumbModule,
      providers: [
        options.breadcrumbOptionsProvider || {
          provide: BREADCRUMB_OPTIONS,
          useValue: options.config,
        },
      ],
    };
  }
}
