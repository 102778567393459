<nav>
  <ol>
    <li *ngFor="let breadcrumb of breadcrumbService.breadcrumbs$ | async; last as isLast">
      <a [routerLink]="breadcrumb.path" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        {{ breadcrumb.label }}
      </a>
      <mat-icon *ngIf="!isLast" class="chevron-icon" svgIcon="chevron-right"></mat-icon>
    </li>
  </ol>
</nav>
