import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { FooterComponent } from './footer.component';
import { LogoModule } from '../logo/logo.module';
import { NavItemModule } from '../nav-item/nav-item.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, MatToolbarModule, LogoModule, NavItemModule, MatIconModule, MatButtonModule],
  exports: [FooterComponent],
})
export class FooterModule {}
