import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, first, map } from 'rxjs';
import { SessionRepository } from 'src/app/auth/state/session.repository';

@Injectable({
  providedIn: 'root',
})
export class NotAuthGuard implements CanActivate {
  constructor(private router: Router, private sessionRepository: SessionRepository) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.sessionRepository.loggedIn$.pipe(
      first(),
      map(loggedIn => {
        if (loggedIn) {
          this.router.navigateByUrl('/users');
        }

        return !loggedIn;
      })
    );
  }
}
