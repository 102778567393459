import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { TokenResponse } from 'src/app/core/token/interfaces/token-response.model';
import { environment } from 'src/environments/environment';

import { LoginCredentials } from '../shared/components/interfaces/login-credentials.model';
import { SessionRepository } from './session.repository';

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(private sessionRepository: SessionRepository, private http: HttpClient, private router: Router) {}

  login(credentials: LoginCredentials): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${environment.apiUrl}/token`, credentials).pipe(
      tap(response => {
        this.sessionRepository.updateToken(response);
      }),
      this.sessionRepository.trackSessionRequestStatus('session')
    );
  }

  logout(): void {
    this.sessionRepository.clearSession();
    this.router.navigateByUrl('/login');
  }

  /**
   * Sends the user email with callback url to reset his password.
   *
   * POST /reset-password
   *
   * @param email
   */
  resetPassword(email: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/reset-password`, {
      email,
      callback_url: `${window.location.origin}/set-new-password`,
    });
  }

  /**
   * Changes the users password to the newly entered value
   *
   * PUT /reset-password/{hash}
   *
   * @param hash
   * @param newPassword
   */
  setNewPassword(hash: string, newPassword: string): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/reset-password/${hash}`, {
      newPassword,
    });
  }
}
