/**
 * NavItemComponent
 * @author Cedomir Djosic <cedomir.djosic@q.agency>
 *
 * @input {NavItem} navItem
 */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavItem } from '../../interfaces/nav-item.model';

@Component({
  selector: '[app-nav-item]',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent {
  @Input() navItem: NavItem | null;

  constructor() {
    this.navItem = null;
  }
}
