import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { SessionService } from 'src/app/auth/state/session.service';
import { LinkType } from 'src/app/shared/interfaces/nav-item.model';

import { HeaderService } from '../../header.service';
import { MOBILE_MENU_OVERLAY_REF } from '../../tokens/mobile-menu-overlay-ref.token';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {
  menuItems$ = this.headerService.navItems$;
  profileItems$ = this.headerService.profileMenuNavItems$;

  constructor(
    @Inject(MOBILE_MENU_OVERLAY_REF) private overlayRef: OverlayRef,
    private sessionService: SessionService,
    private headerService: HeaderService
  ) {}

  closeDialogClick(): void {
    this.overlayRef.dispose();
  }

  navItemClick(): void {
    this.overlayRef.dispose();
  }

  profileMenuItemClick(type?: LinkType): void {
    if (type === LinkType.Logout) {
      this.sessionService.logout();
    }
    this.overlayRef.dispose();
  }
}
