import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { NavItemComponent } from './nav-item.component';

@NgModule({
  declarations: [NavItemComponent],
  imports: [CommonModule, RouterModule, TranslocoModule],
  exports: [NavItemComponent],
})
export class NavItemModule {}
