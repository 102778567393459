<a
  *transloco="let t"
  [ngClass]="navItem?.navItemAnchorCSSClass || {}"
  [routerLink]="navItem?.path"
  [queryParams]="navItem?.queryParams"
  [fragment]="navItem?.fragment"
  routerLinkActive="nav-item-active"
  [routerLinkActiveOptions]="{ exact: true }"
>
  <ng-container *ngIf="navItem?.localisationKey; else nonTranslatedText">
    {{ t(navItem?.localisationKey ?? '') }}
  </ng-container>
  <ng-template #nonTranslatedText>{{ navItem?.linkText }}</ng-template>
</a>
