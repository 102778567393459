import { createStore, select, setProp, withProps } from '@ngneat/elf';
import {
  clearRequestsStatus,
  createRequestsStatusOperator,
  selectIsRequestPending,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { TokenResponse } from 'src/app/core/token/interfaces/token-response.model';
import { Observable } from 'rxjs';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

export interface SessionProps {
  token?: TokenResponse;
}

@Injectable({ providedIn: 'root' })
export class SessionRepository {
  private store;

  trackSessionRequestStatus;
  loggedIn$: Observable<boolean>;
  requestLoading$: Observable<boolean>;

  constructor() {
    this.store = this.createStore();
    this.trackSessionRequestStatus = createRequestsStatusOperator(this.store);
    this.loggedIn$ = this.store.pipe(select(state => !!state.token?.token_key));
    this.requestLoading$ = this.store.pipe(selectIsRequestPending('session'));

    persistState(this.store, {
      key: 'session',
      storage: localStorageStrategy,
    });
  }

  updateToken(token: TokenResponse): void {
    this.store.update(setProp('token', token), clearRequestsStatus());
  }

  getTokenKey(): string | null | undefined {
    return this.store.getValue().token?.token_key;
  }

  clearSession(): void {
    this.store.update(setProp('token', undefined));
  }

  private createStore(): typeof store {
    const store = createStore({ name: 'session' }, withProps<SessionProps>({}), withRequestsStatus<'session'>());

    return store;
  }
}
