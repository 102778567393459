<ng-container *transloco="let t; read: 'resetPassword'">
  <mat-form-field appearance="outline">
    <mat-label>{{ t('email') }}</mat-label>
    <input matInput type="email" [formControl]="emailControl" (keydown.enter)="resetPassword()" />
    <mat-error *ngIf="emailControl.hasError('required')">
      {{ t('emailRequiredValidation') }}
    </mat-error>

    <mat-error *ngIf="emailControl.hasError('email') && !emailControl.hasError('required')">
      {{ t('validEmail') }}
    </mat-error>
  </mat-form-field>

  <button
    mat-raised-button
    color="primary"
    class="mt-standard reset-button"
    [disabled]="emailControl.invalid"
    (click)="resetPassword()"
  >
    {{ t('resetPassword') }}
  </button>
</ng-container>
