<mat-toolbar class="nav-toolbar" *transloco="let t">
  <nav class="nav-container">
    <app-logo [logoSvgSrc]="'/assets/images/q-logo.svg'"> </app-logo>
    <!-- [logoLink]="'/home'" we can add custom URL -->
    <!-- [logoSvgCSSClass]="'test-logo-svg-class'"  we can use custom class in order to change SVG -->

    <ng-container *ngIf="loggedIn$ | async">
      <ul class="nav-items">
        <li *ngFor="let item of headerService.navItems$ | async" app-nav-item [navItem]="item" class="nav-item"></li>
      </ul>

      <div class="nav-actions">
        <button mat-icon-button>
          <mat-icon matBadge="15">notifications</mat-icon>
        </button>
        <img class="avatar" src="assets/images/avatar.svg" [matMenuTriggerFor]="menu" />
        <mat-menu #menu="matMenu">
          <button
            *ngFor="let item of headerService.profileMenuNavItems$ | async"
            mat-menu-item
            [routerLink]="item.path"
            (click)="profileMenuItemClick(item?.type)"
          >
            <ng-container *ngIf="item.localisationKey; else nonTranslatedText">
              {{ t(item.localisationKey) }}
            </ng-container>
            <ng-template #nonTranslatedText>{{ item.linkText }}</ng-template>
          </button>
        </mat-menu>
      </div>

      <button mat-icon-button class="mobile-menu-btn" (click)="toggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </ng-container>
  </nav>
</mat-toolbar>
