import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  isObservable,
  map,
  mergeMap,
  of,
  shareReplay,
  startWith,
} from 'rxjs';

import { BreadcrumbConfig } from './interfaces/breadcrumb-config.model';
import { Breadcrumb } from './interfaces/breadcrumb.model';
import { BREADCRUMB_OPTIONS } from './tokens/breadcrumb-options.token';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  /**
   * Used by the breadcrumb component to display breadcrumb items.
   * If needed, can be directly used but don't forget to unsubscribe from
   * observable to prevent memory leak.
   */
  breadcrumbs$ = this.getBreadcrumbs().pipe(shareReplay(1));

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(BREADCRUMB_OPTIONS) private config: BreadcrumbConfig
  ) {}

  /**
   * Traverses the root route and finds the route at the bottom of the tree.
   * We can't just inject ActivatedRoute and expect to get the
   * actual active route since ActivatedRoute is tied to the component in which it is injected.
   * Because of this, we have to traverse root route to get the actual activated route.
   *
   * For more info, check this github issue and proposed solution by Angular Contributor
   * which is more or less implemented here. https://github.com/angular/angular/issues/11812#issuecomment-325228258
   * @param rootRoute Root route of application.
   * @returns Currently active Route (last leaf of the root tree)
   */
  traverseToBottomOfRouteTree(rootRoute: ActivatedRoute): ActivatedRoute {
    let route = rootRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }

  /**
   * Converts list of ActivatedRoutes to list of Breadcrumb objects.
   * Omits routes that do not have breadcrumb label defined.
   * @param routes Routes to traverse
   * @returns List of Breadcrumb objects to use for a breadcrumb.
   */
  routesToBreadcrumbs(routes: ActivatedRoute[]): Observable<Breadcrumb[]> {
    const breadcrumbs: Observable<Breadcrumb>[] = [this.getHomeBreadcrumb()];

    routes
      .filter(route => !!route.snapshot.data['breadcrumbTranslationKey']) // Remove routes for which the breadcrumb data is not defined.
      .forEach(route => {
        const path = this.routeToPath(route);
        const labelKey = route.snapshot?.data['breadcrumbTranslationKey'];
        const label = this.config.translationGetter(labelKey);

        // Because translationGetter supports both string and Observable<string> return types.
        if (isObservable(label)) {
          const breadcrumb = label.pipe(map(label => ({ label, path })));
          breadcrumbs.push(breadcrumb);
        } else {
          breadcrumbs.push(of({ label, path }));
        }
      });

    return combineLatest(breadcrumbs);
  }

  /**
   * Generating the actual path from root
   * to the current ActivatedRoute
   * @param route Route for which to generate path
   * @returns path of the route, e. g. '/users/list'
   */
  routeToPath(route: ActivatedRoute): string {
    return `/${route.pathFromRoot
      .map(ar => ar.snapshot.url)
      .flat()
      .map(f => f.path)
      .join('/')}`;
  }

  getHomeBreadcrumb(): Observable<Breadcrumb> {
    const labelKey = this.config.homeLabelKey ?? 'home';
    const label = this.config.translationGetter(labelKey);
    const path = this.config.homePath ?? '/';

    if (isObservable(label)) {
      return label.pipe(map(label => ({ label, path })));
    } else {
      return of({ label, path });
    }
  }

  /**
   * Use breadcrumbs$ instead.
   * @returns Observable of Breadcrumb objects.
   */
  private getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      startWith(this.router),
      distinctUntilChanged(),
      map(() => this.traverseToBottomOfRouteTree(this.route)),
      filter(route => route.outlet === 'primary'), // Only interested in primary outlet currently.
      mergeMap(route => this.routesToBreadcrumbs(route.pathFromRoot))
    );
  }
}
