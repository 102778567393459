import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MatIconRegistryService {
  constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  registerSvgIcons(): void {
    this.matIconRegistry.addSvgIconResolver((name, _) => {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icons/${name}.svg`);
    });
  }
}
