import { Observable, Subject, takeUntil, timer } from 'rxjs';

import { AlertTimer } from './interfaces/alert-timer';

/**
 * OneShotAlertTimer works exactly as it sounds.
 * It is started, can be paused and resumed any number of times
 * but once it completes, there is no restarting as {@link expired$} is completed.
 */
export class OneShotAlertTimer implements AlertTimer {
  private timeLeft: number = 0;
  private timeEnd: number = 0;

  paused$ = new Subject<void>();
  expired$ = new Subject<void>();

  start(duration: number): void {
    if (duration < 1) {
      return;
    }

    this.triggerInternalTimer(duration).subscribe(() => {
      this.expired$.next();
      this.expired$.complete();
    });

    this.timeLeft = duration;
    this.timeEnd = new Date().getTime() + this.timeLeft;
  }

  pause(): void {
    this.timeLeft = this.timeEnd - new Date().getTime();
    this.paused$.next();
  }

  resume(): void {
    this.triggerInternalTimer(this.timeLeft).subscribe(() => {
      this.expired$.next();
      this.expired$.complete();
    });
  }

  private triggerInternalTimer(duration: number): Observable<0> {
    return timer(duration).pipe(takeUntil(this.paused$));
  }
}
