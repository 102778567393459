import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NavItem } from '../../interfaces/nav-item.model';
import { footerNavLinks } from '../../constants/footer-nav-link';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  navItems$: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>(footerNavLinks);
}
