import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';
import { SessionService } from 'src/app/auth/state/session.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  protected emailControl = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.email],
  });

  constructor(
    public readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translocoService: TranslocoService,
    private readonly sessionService: SessionService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetPassword(): void {
    if (this.emailControl.invalid) {
      return;
    }

    this.emailControl.disable();

    this.sessionService
      .resetPassword(this.emailControl.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.alertService.success(
            this.translocoService.translate('resetPassword.successResendLink', { email: this.emailControl.value })
          );
          this.router.navigateByUrl('/login');
        },
        error: () => {
          this.alertService.error(
            this.translocoService.translate('resetPassword.failedResendLink', { email: this.emailControl.value })
          );
          this.emailControl.enable();
        },
      });
  }
}
