import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Inject, Injectable } from '@angular/core';
import { filter } from 'rxjs';

import { AlertModule } from './alert.module';
import { AlertContainerComponent } from './components/alert-container/alert-container.component';
import { ALERT_DEFAULT_CONFIG } from './constants/default-alert-config';
import { AlertConfig, AlertContentType } from './interfaces/alert-config';

@Injectable({ providedIn: AlertModule })
export class AlertService {
  private alertContainer: ComponentRef<AlertContainerComponent> | null = null;

  constructor(private overlay: Overlay, @Inject(ALERT_DEFAULT_CONFIG) private defaultConfig: AlertConfig) {}

  success(content: AlertContentType, config?: AlertConfig): ReturnType<typeof this.openAlert> {
    return this.openAlert(content, { ...config, alertType: 'success' });
  }

  error(content: AlertContentType, config?: AlertConfig): ReturnType<typeof this.openAlert> {
    return this.openAlert(content, { ...config, alertType: 'error' });
  }

  warn(content: AlertContentType, config?: AlertConfig): ReturnType<typeof this.openAlert> {
    return this.openAlert(content, { ...config, alertType: 'warn' });
  }

  info(content: AlertContentType, config: AlertConfig = {}): ReturnType<typeof this.openAlert> {
    return this.openAlert(content, { ...config, alertType: 'info' });
  }

  openAlert(content: AlertContentType, config?: AlertConfig): void {
    if (this.alertContainer === null) {
      this.alertContainer = this.createAlertContainer();
    }

    const alertConfig = {
      ...this.defaultConfig,
      ...config,
      content: content,
    } as AlertConfig;

    this.alertContainer.instance.addAlert(alertConfig);
  }

  private createAlertContainer(): ComponentRef<AlertContainerComponent> {
    const overlayRef = this.overlay.create({ panelClass: 'full-width' });
    const alertContainerPortal = new ComponentPortal(AlertContainerComponent);

    const componentRef = overlayRef.attach(alertContainerPortal);

    // Automatically dispose overlayRef once there is no more alerts visible.
    componentRef.instance.alertCountChange.pipe(filter(count => count === 0)).subscribe(() => {
      overlayRef.dispose();
      this.alertContainer = null;
    });

    return componentRef;
  }
}
