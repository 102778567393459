import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { Router } from '@angular/router';

import { TranslocoRootModule } from './core/translations/transloco-root.module';
import { AppRoutingModule } from './app-routing.module';
import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { AppComponent } from './app.component';
import { BreadcrumbModule } from './shared/components/breadcrumb/breadcrumb.module';
import { BREADCRUMB_OPTIONS } from './shared/components/breadcrumb/tokens/breadcrumb-options.token';
import { BreadcrumbConfig } from './shared/components/breadcrumb/interfaces/breadcrumb-config.model';
import { httpInterceptorProviders } from './core/interceptors';
import { AlertConfig } from './shared/components/alert/interfaces/alert-config';
import { ALERT_DEFAULT_CONFIG } from './shared/components/alert/constants/default-alert-config';
import { MatIconRegistryService } from './core/icons/mat-icon-registry.service';

export function initializeApp(matIconRegistryService: MatIconRegistryService): () => void {
  return () => {
    /**
     * Update in the future if additional initialization logic needed in the app.
     * Return Observable<...> if need to do async task on initialization (such as load some config etc.)
     */
    matIconRegistryService.registerSvgIcons();
  };
}

export function breadcrumbOptionsFactory(translocoService: TranslocoService): BreadcrumbConfig {
  return {
    homeLabelKey: 'breadcrumb.home',
    translationGetter: key => translocoService.selectTranslate(key),
  } as BreadcrumbConfig;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    HeaderModule,
    FooterModule,
    BreadcrumbModule.forRoot({
      breadcrumbOptionsProvider: {
        provide: BREADCRUMB_OPTIONS,
        useFactory: breadcrumbOptionsFactory,
        deps: [TranslocoService],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [MatIconRegistryService, TraceService],
      multi: true,
    },
    {
      provide: ALERT_DEFAULT_CONFIG,
      useValue: { duration: 0, limitAlertNumber: 3 } as AlertConfig,
    },
    httpInterceptorProviders,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' } as MatFormFieldDefaultOptions,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
