<ng-container [ngSwitch]="config.alertType">
  <mat-icon *ngSwitchCase="'success'">check_circle</mat-icon>
  <mat-icon *ngSwitchCase="'error'">error</mat-icon>
  <mat-icon *ngSwitchCase="'warn'">warning</mat-icon>
  <mat-icon *ngSwitchCase="'info'">info</mat-icon>
</ng-container>

<!-- Need to have enclosing div as Portal inserts component as last child of parent element. -->
<div>
  <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
</div>

<button mat-icon-button (click)="dismiss()">
  <mat-icon>close</mat-icon>
</button>

<!-- If AlertContentType is string, we use this template to show it. -->
<ng-template #contentStringTemplate>
  <span>{{ config.content }}</span>
</ng-template>
