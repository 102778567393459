import { LinkType, NavItem } from '../interfaces/nav-item.model';

export const headerNavLinks: NavItem[] = [
  {
    linkText: 'Home',
    path: '/login',
    navItemAnchorCSSClass: 'mat-body-1',
    localisationKey: 'navItems.home',
  },
  {
    linkText: 'Categories',
    path: '/categories',
    navItemAnchorCSSClass: 'mat-body-1',
    localisationKey: 'navItems.categories',
  },
  {
    linkText: 'Sale',
    path: '/sale',
    navItemAnchorCSSClass: 'mat-body-1',
    localisationKey: 'navItems.sale',
  },
  {
    linkText: 'Contact Us',
    path: '/contactus',
    navItemAnchorCSSClass: 'mat-body-1',
    localisationKey: 'navItems.contactUs',
  },
];

export const profileMenuNavLinks: NavItem[] = [
  {
    linkText: 'My profile',
    path: 'profile',
    localisationKey: 'navItems.profile',
  },
  {
    linkText: 'Users',
    path: '/users/list',
    localisationKey: 'navItems.users',
  },
  {
    linkText: 'My Drive',
    path: '/my-drive',
    localisationKey: 'navItems.myDrive',
  },
  {
    linkText: 'Sign out',
    localisationKey: 'navItems.signOut',
    type: LinkType.Logout,
  },
];
